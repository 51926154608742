.swiper-pagination{
    position: static;
    margin-top: 10px;
}
.swiper-pagination-bullet{
    background-color: #c5c5c5;
    opacity: 1;
}
.swiper-pagination-bullet-active{
    background-color: #E8D088;
}