%shimmer-animation {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}
.MuiStep-root:nth-of-type(2) .MuiStepConnector-line {
  margin-left: -12px;
}

%shimmer-bg {
  @extend %shimmer-animation;
  background: #efefef;
  // transform: skewX(-45deg);

  background-image: linear-gradient(
    120deg,
    #efefef 0%,
    #efefef 30%,
    #fff 40%,
    #efefef 50%,
    #efefef 100%
  );
}

.shimmerBG {
  @extend %shimmer-bg;
  background-size: 2000px 100%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -2000px 0;
  }
  100% {
    background-position: 2000px 0;
  }
}
.form_contact_us {
  ::placeholder {
    color: black;
  }
}
.react-tel-input {
  .form-control {
    --tw-border-opacity-2: 1;
    width: 100% !important;
    border-color: #e6e6e6 !important;
    // border-radius: 0.125rem;
    font-size: 18px !important;
    height: fit-content !important;
    &.no_radius {
      border-radius: 0 !important;
    }
  }
}
.__react_select {
  border-color: #e6e6e6;
}
.react-select {
  &__control {
    border-color: #aeaeae !important;
    border-radius: 5px !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    // border-style: initial !important;
    // border-width: initial !important;
    min-height: 59px !important;
    &--is-focused {
      --focusOutline: 0;
      --p-colorPrimaryAlpha20: hsla(0, 0%, 0%, 25%);
      --p-colorPrimaryAlpha50: hsla(0, 0%, 0%, 50%);
      --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
      --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20),
        0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
      outline: var(--focusOutline) !important;
      border-color: var(--p-colorPrimaryAlpha50) !important;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
        0px 3px 6px rgba(0, 0, 0, 0.02), var(--focusBoxShadow) !important;
    }
  }
  &__menu {
    z-index: 9999999 !important;
  }
}
.react-tel-input .form-control{
  border-color: #aeaeae!important;
}