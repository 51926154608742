@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoft.otf) format("opentype");
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftThin.otf) format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftExtraLight.otf) format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftLight.otf) format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftMedium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftBold.otf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftExtraBold.otf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftHeavy.otf) format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Caros Soft";
  src: url(./fonts/CarosSoftBlack.otf) format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Thin.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Black.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Autography";
  src: url(./fonts/Autography.otf) format("opentype");
  font-weight: 400;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
  box-shadow: "0px 9px 20px #00000033";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #46217b;
  height: 80px;
  border-radius: 8px;
  /*height, radius */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: "#46217B";
}

input {
  /* background-color: white !important; */
}

.MuiListItemText-dense {
  margin: 0 !important;
}

.MuiListItemIcon-alignItemsFlexStart {
  margin-top: 6px !important;
}

body {
  margin: 0 !important;
  font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  /* background-color: initial; */
}


/* 
html { font-size: 10px; }

@media (min-width: 768px) {
  html { font-size: 12px; }
}

@media (min-width: 1024px) {
  html { font-size: 14px; }
}

@media (min-width: 1440px) {
  html { font-size: 16px; }
} */
.react-select__menu-list .react-select__option--is-focused{
  background-color: #8840f0;
  color: white;
}

@media screen and (min-width:992px) {
  .clip-path-1{
    clip-path:polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  }
  .clip-path-2{
    clip-path:polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    transform: translateX(-50px);
  }
  
}