@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Classes goes here */
@layer utilities {
    /* Keep classes wihtin the layer */
    .break {
        flex-basis: 100%;
        height: 0;
      }
  }

@font-face {
    font-family: 'nafeesregular';
    src: url('./fonts/Nafees Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
*{
    outline: none;
}